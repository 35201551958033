import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { CSVLink } from 'react-csv'
import { Navbar } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { useEffect, useState, useRef } from 'react'
import { Form } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'
import { Col } from 'react-bootstrap'
import AddClientModal from './PerformanceHeaderSubComponents/AddClientModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserPlus,
  faFilter,
  faTableColumns,
  faPenToSquare,
  faIndianRupeeSign,
  faEye,
  faWallet,
  faTrash,
  faShareNodes,
  faClipboard,
  faUpDown,
  faHashtag,
  faCheck,
  faRobot,
  faDownload,
  faPlus,
  faArrowTrendUp
} from '@fortawesome/free-solid-svg-icons'
import CustomizeColumnsModal from './PerformanceHeaderSubComponents/CustomizeColumnsModal'
import SaveViewModal from './PerformanceHeaderSubComponents/SaveViewModal'
import SaveCustomReportModal from './PerformanceHeaderSubComponents/SaveCustomReportModal'
import ShareCustomReportModal from './PerformanceHeaderSubComponents/ShareCustomReportModal'
import AdvancedFilterModal from './PerformanceHeaderSubComponents/AdvancedFilterModal'
import APIRequestStatusModal from './CommonSubComponents/APIRequestStatusMessageModal'
import { Tooltip } from 'react-bootstrap'
import BotSuggestionModal from './PerformanceHeaderSubComponents/BotSuggestionModal'
import { TabsATUI } from './ui/tabs'

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from './ui/drawer.js'
import { ShadCNButton } from '../components/ui/shadcn-button'
import { Footprints, Minus, Plus } from 'lucide-react'
import { ResponsiveContainer } from 'recharts'

function PerformanceHeader ({
  columnFilters,
  selectedLevel,
  setSelectedLevel,
  setCampaignSearch,
  setAdsetSearch,
  setAdSearch,

  campaignSearch,
  adsetSearch,
  adSearch,

  setStartDate,
  setEndDate,
  defaultStartDate,
  defaultEndDate,
  setNewAdvanceFilter,
  advancedFiltersSelections,
  selectedColumnFilters,
  setSelectedColumnFilters,
  setSelectedAdAccount: setSelectedAdAccounts,
  setSelectedLeadType,
  selectedLeadType,
  selectedAdAccount: selectedAdAccounts,
  defaultAdvancedFilters,
  setAdvancedFilterModalShow,
  advancedFilterModalShow,
  selectedIDS,
  statusFilters,
  setStatusFilters,
  contentTypeFilters,
  setContentTypeFilters,
  consolidationType,
  setConsolidationType,
  setHideStatusFilter,
  APIEndpointDomain,
  allViews,
  setAllViews,
  Data,
  setData,
  getPrimaryTD,
  startDate,
  endDate,
  setComparisonTableData,
  compareRangeRequirement,
  setCompareRangeRequirement,
  loadingP,
  setLoadingP,
  tableDataAPICalling,
  favoritesData,
  setFavoritesData,
  favoriteCAIDs,
  setFavoriteCAIDs,
  favoriteASIDs,
  setFavoriteASIDs,
  favoriteAIDs,
  setFavoriteAIDs,
  setCustomReportNames,
  customReportNames,
  selectedCustomReport,
  setSelectedCustomReport,
  goTriggerPrimaryAPI,
  setGoTriggerPrimaryAPI,

  campaignExclusions,
  setCampaignExclusions,
  adsetExclusions,
  setAdsetExclusions,
  adExclusions,
  setAdExclusions,
  dateExclusions,
  setDateExclusions,
  currentCampaign,
  setCurrentCampaign,
  currentAdset,
  setCurrentAdset,
  currentAd,
  setCurrentAd,
  currentDate,
  setCurrentDate,

  showAvgComparisonIndicators,
  setShowAvgComparisonIndicators,
  selectedAIDCount,
  showAdTagModal,
  setShowAdTagModal,
  setClusterNames,
  clusterNames,
  selectedClusterNames,
  setSelectedClusterNames,
  exportCSVData,
  setExportCSVData,
  getClustersPrimaryTD,
  aggregateAds,
  setAggregateAds,

  AttributedClients,
  AttributedRevenue,
  setAttributedClients,
  setAttributedRevenue,
  tableData,
  getTrends,
  showTrends,
  setShowTrends,
  getClusters,
  getCustomReports,
  getFavorites,
  getViews,
  totalsData,
  showSpendTrends,
  setShowSpendTrends,
  channel
}) {
  var [tagSearch, setTagSearch] = useState('')

  var [showBotSuggestionModal, setShowBotSuggestionModal] = useState(false)
  var [columnModalShow, setColumnModalShow] = useState(false)
  var [addClientModalShow, setAddClientModalShow] = useState(false)
  var [clientFormData, setClientFormData] = useState({
    campaign_id: 0,
    adset_id: 0,
    ad_id: 0,
    amount_sold: 0,
    converted_at: '',
    created_at: '',
    lead_type: 'Premium',
    ad_account: 'MiM_Essay'
  })

  var adAccounts =
    channel == 'meta'
      ? [
          'Prohed',
          'MentR-Me',
          'MiM-Essay',
          'MiM-Essay-5',
          'MiM-Essay Alternate',
          'Prohed-2',
          'MiM-Essay Instagram'
        ]
      : ['MiM-Essay_GA', 'MentR-Me_GA']

  var [currentAccountBalance, setCurrentAccountBalance] = useState(null)

  var [showShareCustomReportModal, setShowCustomReportModal] = useState(false)
  var [ShareCustomReportWith, setShareCustomReportWith] = useState('')
  var [
    ShareCustomReportWithErrorMessage,
    setShareCustomReportWithErrorMessage
  ] = useState('')

  var [showCustomReportShareStatusAlert, setShowCustomReportShareStatusAlert] =
    useState(false)
  var [CustomReportShareStatusMessage, setCustomReportShareStatusMessage] =
    useState('')

  function ShareCustomReport (report_name) {
    axios
      .get(APIEndpointDomain + '/performance/share-custom-report', {
        params: {
          custom_report_name: report_name,
          share_with: ShareCustomReportWith
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setShowCustomReportModal(false)
        setShowCustomReportShareStatusAlert(true)
        setCustomReportShareStatusMessage('Custom report shared successfully!')
      })
      .catch(error => {
        setShareCustomReportWithErrorMessage(error.data['message'])
        setShowCustomReportModal(false)

        setShowCustomReportShareStatusAlert(true)
        setCustomReportShareStatusMessage('Failed to share your custom report!')
      })
  }

  var [currentAccountBalanceClick, setCurrentAccountBalanceClick] =
    useState(false)

  // function getCurrentAdAccountBalance (ad_account) {
  //   axios
  //     .get(APIEndpointDomain + '/performance/account-balance', {
  //       params: {
  //         ad_account: ad_account
  //       },
  //       headers: {
  //         'ngrok-skip-browser-warning': 'any',
  //         'Access-Control-Allow-Origin': '*',
  //         Authorization: `Bearer ${localStorage.getItem('access_token')}`
  //       }
  //     })
  //     .then(response => {
  //       console.log(response)
  //       const balance = response.data['balance']
  //       setCurrentAccountBalance(Number(balance))
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }

  var [showViewDeleteStatusAlert, setShowViewDeleteStatusAlert] =
    useState(false)
  var [viewDeleteStatusMessage, setviewDeleteStatusMessage] = useState('')

  function deleteView (name) {
    axios
      .get(APIEndpointDomain + '/performance/delete-view', {
        params: {
          name: name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAllViews(response.data['views'])
        setSelectedView('')
        setShowViewDeleteStatusAlert(true)
        setviewDeleteStatusMessage('View has been deleted successfully!')
      })
      .catch(error => {
        console.log(error)
        setShowViewDeleteStatusAlert(true)
        setviewDeleteStatusMessage('Failed to delete your view!')
      })
  }

  function deleteFavorites () {
    axios
      .get(APIEndpointDomain + '/performance/delete-favorites', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['favorite']
        setFavoritesData(favorites)
        setFavoriteCAIDs(response.data['caids'])
        setFavoriteASIDs(response.data['asids'])
        setFavoriteAIDs(response.data['aids'])
        setSelectedCustomReport('')
      })
      .catch(error => {
        console.log(error)
      })
  }

  function copyCustomReport (name) {
    const url =
      window.location.origin +
      '/performance/custom-report/' +
      encodeURIComponent(name)

    navigator.clipboard.writeText(url)
    // Create a temporary textarea element to hold the URL
    const textArea = document.createElement('textarea')
    textArea.value = url

    // Append the textarea element to the document
    document.body.appendChild(textArea)

    // Select the URL in the textarea
    textArea.select()

    // Execute the copy command
    document.execCommand('copy')

    // Remove the temporary textarea element
    document.body.removeChild(textArea)

    // Check if the URL was successfully copied to the clipboard
    // if (document.queryCommandSupported("copy")) {
    //   console.log("URL copied to clipboard: " + url);
    // } else {
    //   console.error("Copy to clipboard not supported in this browser.");
    // }
  }

  // useEffect(() => {
  //   if (!selectedAdAccount.toLowerCase().includes('_ga')) {
  //     getCurrentAdAccountBalance(selectedAdAccount)
  //   } else {
  //     setCurrentAccountBalance(null)
  //   }
  // }, [currentAccountBalance, currentAccountBalanceClick, selectedAdAccount])

  function handleClientModalSubmit () {
    setAddClientModalShow(false)
    axios
      .post(
        APIEndpointDomain + '/add-client',
        {
          created_at: clientFormData.created_at,
          converted_at: clientFormData.converted_at,
          amount_sold: clientFormData.amount_sold,
          ad_account: clientFormData.ad_account,
          campaign_id: clientFormData.campaign_id,
          adset_id: clientFormData.adset_id,
          ad_id: clientFormData.ad_id,
          lead_type: clientFormData.lead_type
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function handleAdvanceFilterChange (value, key) {
    var currentAdvanceFilterSelections = advancedFiltersSelections

    currentAdvanceFilterSelections[key] = value
    setNewAdvanceFilter(currentAdvanceFilterSelections)
  }

  function levelSelector (level) {
    setSelectedLevel(level)
  }

  var [namingView, setNamingView] = useState('')
  var [selectedView, setSelectedView] = useState('')

  function selectView (name, views) {
    setSelectedView(name)

    axios
      .get(APIEndpointDomain + '/select-view', {
        params: {
          name: name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const columns = response.data['columns']
        const advancedFilters = response.data['advanced_filters']
        const consolidationTypeSaved = response.data['consolidation_type']
        const contentType = response.data['content_type']
        const adStatus = response.data['ad_status']

        contentType['reel'] = contentType['reel'] === 'True' ? true : false
        contentType['image'] = contentType['image'] === 'True' ? true : false

        setNewAdvanceFilter(advancedFilters)
        setSelectedColumnFilters(columns)
        setConsolidationType(consolidationTypeSaved)
        setContentTypeFilters(contentType)
        setStatusFilters(adStatus)
      })
      .catch(error => {
        console.log(error)
      })

    setNewAdvanceFilter(views[name].advanced_filters)
    setSelectedColumnFilters(views[name].columns)
    setConsolidationType(views[name].consolidation_type)
    setContentTypeFilters(views[name].content_type)
    setStatusFilters(views[name].ad_status)
  }

  var [showViewSaveStatusAlert, setShowViewSaveStatusAlert] = useState(false)
  var [viewSaveStatusMessage, setviewSaveStatusMessage] = useState('')

  function saveViewSubmit (
    advancedFilterSelections,
    selectedColumnFilters,
    namingView
  ) {
    setShowNameYourViewModal(false)
    axios
      .post(
        APIEndpointDomain + '/save-view',
        {
          name: namingView,
          columns: selectedColumnFilters,
          advanced_filters: advancedFilterSelections,
          ad_status: statusFilters,
          consolidation_type: consolidationType,
          content_type: contentTypeFilters
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        const newViews = [...allViews, { name: namingView }]
        setAllViews(newViews)
        setShowViewSaveStatusAlert(true)
        setviewSaveStatusMessage('Your view has been saved successfully!')
        setSelectedView(namingView)
      })
      .catch(error => {
        setShowViewSaveStatusAlert(true)
        setviewSaveStatusMessage('Failed to save the view!')
      })
  }

  function showColumnModal () {
    var showCModal =
      columnModalShow === false
        ? setColumnModalShow(true)
        : setColumnModalShow(false)
    return showCModal
  }

  function columnSelector (selectedColumn) {
    var selectedColumns = selectedColumnFilters.includes(selectedColumn)
      ? selectedColumnFilters.filter(column => column !== selectedColumn)
      : [...selectedColumnFilters, selectedColumn]

    setSelectedColumnFilters(selectedColumns)
  }

  function columnFilterColor (filter) {
    var color = selectedColumnFilters.includes(filter) ? 'success' : 'black'
    return color
  }

  function showAdvancedFiltersModal () {
    var showAFModal =
      advancedFilterModalShow === false
        ? setAdvancedFilterModalShow(true)
        : setAdvancedFilterModalShow(false)
    return showAFModal
  }

  function countAppliedFilters (defaultFilters, customFilters) {
    let changedCount = 0

    for (const key in defaultFilters) {
      if (defaultFilters.hasOwnProperty(key)) {
        if (customFilters[key] !== defaultFilters[key]) {
          changedCount++
        }
      }
    }

    return changedCount
  }

  function resetFilters () {
    setNewAdvanceFilter(defaultAdvancedFilters)
    setStatusFilters({
      active: true,
      paused: true
    })
    setContentTypeFilters({
      reel: true,
      image: true
    })

    setCampaignSearch('')
    setAdsetSearch('')
    setAdSearch('')

    setSelectedView('')
  }

  function handleCampaignSearch (e) {
    setCampaignSearch(e.target.value)
  }

  function handleAdsetSearch (e) {
    setAdsetSearch(e.target.value)
  }

  function handleAdSearch (e) {
    setAdSearch(e.target.value)
  }

  function handleStartDate (e) {
    setStartDate(e.target.value)
  }

  function handleEndDate (e) {
    setEndDate(e.target.value)
  }

  var [tabMessages, setTabMessages] = useState({
    campaign: '',
    adset: '',
    ad: ''
  })

  var [showNameYourViewModal, setShowNameYourViewModal] = useState(false)

  var columnCategory = {
    'Ad Metrics': [
      'spend',
      'impressions',
      'reach',
      'three_sec_plays',
      'thruplays',
      'twenty_five_p_plays',
      'fifty_p_plays',
      'seventy_five_p_plays',
      'link_clicks',
      'cpm',
      'cost_per_thruplay',
      'cpc',
      'ctr',
      'hook_rate',
      'hold_rate',
      'view_to_click',
      'click_to_lead',
      'campaign_id',
      'adset_id',
      'ad_id',
      'fifteen_to_25p',
      'twenty_five_p_to_50p',
      'fifty_p_to_75p',
      'held_per_1k_impressions'
    ],

    'Current Stages': [
      'nurturing',
      'dnp',
      'no_show',
      'backburners',
      'lead_lost'
    ],

    'Standard Appointment Metrics': [
      'admit_up',
      'admit_up_rev',
      'actual_acquisitions',
      'acquisitions_lost',
      'acquisition_loss_rate',
      'acquisition_validation_rate',
      'acquisitions_pending_validation',
      'acquisitions_pending_validation_p',
      'avg_validation_period',
      'avg_complete_cycle_period',
      'cpaa',
      'premium_ms_revenue',
      'acquisitions',
      'avg_purchase',
      'avg_conversion_period',
      'adset_name',
      'budget',
      'campaign_name',
      'cpa',
      'cpgl',
      'cpl',
      // "cpm",
      'cpql',
      // "cpv",
      'days_active',
      'deleted',
      'dnp_recovered',
      'good_leads',
      'good_percentage',
      'leads',
      // "link_clicks",
      'pending',
      'pending_p',
      'pressure_sell',
      'pressure_to_acquisition',
      'projected_acquisitions',
      'projected_cpa',
      'projected_roi',
      'qualified',
      'quality',
      // "reach",
      'revenue',
      'roi',
      // "status",
      // "thruplays",
      // "three_sec_plays",
      'total_revenue',
      'ultra_hot',
      'uhl_to_acquisition',
      'upfront_payments'
      // "view_to_click",
    ],
    'During Metrics': [
      'cpaa_during',
      'premium_ms_revenue_during',
      'admit_up_during',
      'admit_up_rev_dur',
      'actually_acquired_during',
      'acquired_during',
      'cb_during',
      'cpa_during',
      'deleted_during',
      'dnp_during',
      'revenue_during',
      'roi_during',
      'real_revenue_during',
      'real_acquisitions_during',
      'real_roi_during',
      'cpra_during',
      // "seventy_five_p_plays",

      'total_revenue_during',

      'upfront_payments_during'
    ],
    'Historic Metrics': [
      'cpgl_historic',
      'cpuhl_h',

      // "fifty_p_plays",

      // "hold_rate_50_p",
      // "hold_rate_75_p",
      // "hold_rate_thruplay",
      // "hook_rate",
      'h_good',
      'h_good_percentage',
      'h_pressure',
      'h_ultra',
      'h_acquisitions',
      'lead_to_h_good_percentage',
      'lead_to_h_ultra_percentage',

      'h_cpa'
      // "impressions",
    ]
    // "Calendar Metrics": [
    //   "calls_booked_during",
    //   "valid_calls_cb_p",
    //   "assigned_calls_cb_p",
    //   "calls_left_cb_p",
    //   "dnp_cb_p",
    //   "deleted_cb_p",
    //   "good_leads_cb_p",
    //   "valid_calls_cb",
    //   "assigned_calls_cb",
    //   "calls_left_cb",
    //   "good_leads_cb",
    //   "deleted_cb",
    //   "dnp_cb",
    // ],
  }
  // useEffect(() => {
  //   if (compareRangeRequirement) {
  //     getPrimaryTD(
  //       calculateNewDates(startDate, endDate)[0],
  //       calculateNewDates(startDate, endDate)[1],
  //       selectedLeadType,
  //       selectedAdAccount,
  //       "compare"
  //     );
  //   } else {
  //     setCompareRangeRequirement(false);
  //     // setComparisonTableData([]);
  //   }
  // }, [compareRangeRequirement]);

  var [showNameReportModal, setShowNameReportModal] = useState(false)
  var [namingCustomReport, setNamingCustomReport] = useState('')

  function getCustomReport (name) {
    setSelectedCustomReport(name)
    axios
      .get(APIEndpointDomain + '/performance/get-custom-report', {
        params: {
          name: name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['custom_report']
        setFavoritesData(favorites)
        setFavoriteCAIDs(response.data['caids'])
        setFavoriteASIDs(response.data['asids'])
        setFavoriteAIDs(response.data['aids'])
      })
      .catch(error => {
        console.log(error)
      })

    // setFavoritesData([]);
    // setFavoriteCAIDs([]);
    // setFavoriteASIDs([]);
    // setFavoriteAIDs([]);

    // axios
    //   .get(APIEndpointDomain + "/performance/refresh-favorites", {
    //     params: {
    //       name: name,
    //     },
    //     headers: {
    //       "ngrok-skip-browser-warning": "any",
    //       "Access-Control-Allow-Origin": "*",
    //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     const favorites = response.data["favorite"];
    //     setFavoritesData(favorites);
    //     setFavoriteCAIDs(response.data["caids"]);
    //     setFavoriteASIDs(response.data["asids"]);
    //     setFavoriteAIDs(response.data["aids"]);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  var [
    showCustomReportUpdateStatusAlert,
    setShowCustomReportUpdateStatusAlert
  ] = useState(false)
  var [CustomReportUpdateStatusMessage, setCustomReportUpdateStatusMessage] =
    useState('')

  function saveCustomReport (favorite, name) {
    const encodedData = encodeURIComponent(JSON.stringify(favorite))

    axios
      .get(APIEndpointDomain + '/performance/update-custom-report', {
        params: {
          custom_report_name: name,
          custom_report: encodedData
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)

        const favorites = response.data['favorite']
        setFavoritesData(favorites)
        setFavoriteCAIDs(response.data['caids'])
        setFavoriteASIDs(response.data['asids'])
        setFavoriteAIDs(response.data['aids'])
        setShowCustomReportUpdateStatusAlert(true)
        setCustomReportUpdateStatusMessage(
          'Custom report updated successfully!'
        )
      })
      .catch(error => {
        console.log(error)
        setShowCustomReportUpdateStatusAlert(true)
        setCustomReportUpdateStatusMessage(
          'Failed to update your custom report!'
        )
      })
  }

  var [showCustomReportSaveStatusAlert, setShowCustomReportSaveStatusAlert] =
    useState(false)
  var [CustomReportSaveStatusMessage, setCustomReportSaveStatusMessage] =
    useState('')

  function saveCustomReportAs (favorite) {
    const encodedData = encodeURIComponent(JSON.stringify(favorite))

    axios
      .post(
        APIEndpointDomain + '/performance/save-custom-report',
        {
          favorite: encodedData,
          name: namingCustomReport
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json' // Set content type for POST request
          }
        }
      )
      .then(response => {
        console.log(response)
        const names = response.data['names']
        setCustomReportNames(names)
        setShowNameReportModal(false)
        setSelectedCustomReport(response.data['current_name'])
        setShowCustomReportSaveStatusAlert(true)
        setCustomReportSaveStatusMessage('Custom report saved successfully!')
      })
      .catch(error => {
        console.log(error)
        setShowCustomReportSaveStatusAlert(true)
        setCustomReportSaveStatusMessage('Failed to save your custom report!')
      })
  }

  var [showExclusionModal, setShowExclusionModal] = useState(false)

  useEffect(() => {
    var campaignMessage =
      selectedIDS.selectedCAIDCount > 0
        ? '(' + String(selectedIDS.selectedCAIDCount) + ' Selected)'
        : ''

    var adsetMessage =
      selectedIDS.selectedASIDCount > 0
        ? '(' + String(selectedIDS.selectedASIDCount) + ' Selected)'
        : selectedIDS.selectedCAIDCount > 0 &&
          selectedIDS.selectedASIDCount === 0
        ? '(for ' + String(selectedIDS.selectedCAIDCount) + ' Campaigns)'
        : ''

    var adMessage =
      selectedIDS.selectedAIDCount > 0
        ? '(' + String(selectedIDS.selectedAIDCount) + ' Selected)'
        : selectedIDS.selectedASIDCount > 0 &&
          selectedIDS.selectedAIDCount === 0
        ? '(for ' + String(selectedIDS.selectedASIDCount) + ' Adsets)'
        : selectedIDS.selectedCAIDCount > 0 &&
          selectedIDS.selectedASIDCount === 0 &&
          selectedIDS.selectedAIDCount === 0
        ? '(for ' + String(selectedIDS.selectedCAIDCount) + ' Campaigns)'
        : ''

    setTabMessages(prevTabMessages => ({
      ...prevTabMessages,
      campaign: campaignMessage,
      adset: adsetMessage,
      ad: adMessage
    }))
  }, [selectedIDS])

  var [showClusterDeleteStatusModal, setShowClusterDeleteStatusModal] =
    useState(false)
  var [clusterDeleteStatusMessage, setClusterDeleteStatusMessage] = useState('')

  function deleteCluster (cluster_name) {
    axios
      .get(APIEndpointDomain + '/performance/delete-cluster', {
        params: {
          tag_name: cluster_name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setShowClusterDeleteStatusModal(true)
        setSelectedClusterNames([
          ...selectedClusterNames.filter(cluster => cluster != cluster_name)
        ])
        setClusterDeleteStatusMessage(
          cluster_name + ' cluster deleted successfully'
        )
        setClusterNames(response.data['all_tags'])
      })
      .catch(error => {
        console.log(error)
        setClusterDeleteStatusMessage(
          'Failed to delete ' + cluster_name + ' cluster!'
        )
      })
  }

  // Drag logic

  var dragColumn = useRef()
  var dragOverColumn = useRef()

  function dragColumnStart (__, position) {
    dragColumn.current = position
  }

  function dragColumnEnter (__, position) {
    dragOverColumn.current = position
  }

  function dropColumn (__) {
    var updatedSelectedColumnFilters = [...selectedColumnFilters]
    var dragItemContent = updatedSelectedColumnFilters[dragColumn.current]
    updatedSelectedColumnFilters.splice(dragColumn.current, 1)
    updatedSelectedColumnFilters.splice(
      dragOverColumn.current,
      0,
      dragItemContent
    )
    dragColumn.current = null
    dragOverColumn.current = null
    setSelectedColumnFilters(updatedSelectedColumnFilters)
  }

  function renderButtonTooltip (message) {
    return (
      <Tooltip id='button-tooltip' style={{ background: 'white' }}>
        {message}
      </Tooltip>
    )
  }

  function selectAllClusters (cluster) {
    var filteredClusters = clusterNames.filter(cluster =>
      cluster.toLowerCase().includes(tagSearch.toLowerCase())
    )

    setSelectedClusterNames(filteredClusters)
  }

  function unselectAllClusters (cluster) {
    var filteredClusters = selectedClusterNames.filter(
      cluster => !cluster.toLowerCase().includes(tagSearch.toLowerCase())
    )

    setSelectedClusterNames(filteredClusters)
  }

  function handleGoButtonClick () {
    if (selectedLevel !== 'Clusters') {
      getPrimaryTD(
        startDate,
        endDate,
        selectedLeadType,
        selectedAdAccounts,
        'primary'
      )
      getTrends(selectedLeadType, selectedAdAccounts)
      getClusters()
      getCustomReports()
      getFavorites()
      getViews()
    } else {
      getClustersPrimaryTD(
        selectedClusterNames,
        startDate,
        endDate,
        selectedLeadType
      )
    }
  }

  function handleAttributedClients (e) {
    setAttributedClients(e.target.value)
  }

  function handleAttributedRevenue (e) {
    setAttributedRevenue(e.target.value)
  }

  return (
    <>
      {/* View Status */}
      {/* <ExclusionModal
        campaignExclusions={campaignExclusions}
        setCampaignExclusions={setCampaignExclusions}
        adsetExclusions={adsetExclusions}
        setAdsetExclusions={setAdsetExclusions}
        adExclusions={adExclusions}
        setAdExclusions={setAdExclusions}
        dateExclusions={dateExclusions}
        setDateExclusions={setDateExclusions}
        currentCampaign={currentCampaign}
        setCurrentCampaign={setCurrentCampaign}
        currentAdset={currentAdset}
        setCurrentAdset={setCurrentAdset}
        currentAd={currentAd}
        setCurrentAd={setCurrentAd}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        show={showExclusionModal}
        setShow={setShowExclusionModal}
        setGoTriggerPrimaryAPI={setGoTriggerPrimaryAPI}
        goTriggerPrimaryAPI={goTriggerPrimaryAPI}
        tableDataAPICalling={tableDataAPICalling}
      /> */}

      <APIRequestStatusModal
        show={showViewSaveStatusAlert}
        setShow={setShowViewSaveStatusAlert}
        message={viewSaveStatusMessage}
      />
      <APIRequestStatusModal
        show={showClusterDeleteStatusModal}
        setShow={setShowClusterDeleteStatusModal}
        message={clusterDeleteStatusMessage}
      />

      <APIRequestStatusModal
        show={showViewDeleteStatusAlert}
        setShow={setShowViewDeleteStatusAlert}
        message={viewDeleteStatusMessage}
      />

      <APIRequestStatusModal
        show={showCustomReportSaveStatusAlert}
        setShow={setShowCustomReportSaveStatusAlert}
        message={CustomReportSaveStatusMessage}
      />

      <APIRequestStatusModal
        show={showCustomReportUpdateStatusAlert}
        setShow={setShowCustomReportUpdateStatusAlert}
        message={CustomReportUpdateStatusMessage}
      />

      <APIRequestStatusModal
        show={showCustomReportShareStatusAlert}
        setShow={setShowCustomReportShareStatusAlert}
        message={CustomReportShareStatusMessage}
      />

      <AddClientModal
        addClientModalShow={addClientModalShow}
        setAddClientModalShow={setAddClientModalShow}
        clientFormData={clientFormData}
        setClientFormData={setClientFormData}
        handleClientModalSubmit={handleClientModalSubmit}
      />

      {/* Customize Column Modal */}

      <CustomizeColumnsModal
        columnModalShow={columnModalShow}
        columnCategory={columnCategory}
        showColumnModal={showColumnModal}
        columnFilters={columnFilters}
        columnFilterColor={columnFilterColor}
        selectedColumnFilters={selectedColumnFilters}
        columnSelector={columnSelector}
        dragColumnStart={dragColumnStart}
        dragColumnEnter={dragColumnEnter}
        dropColumn={dropColumn}
      />

      {/* Save name modal */}

      <SaveViewModal
        showNameYourViewModal={showNameYourViewModal}
        setShowNameYourViewModal={setShowNameYourViewModal}
        setNamingView={setNamingView}
        saveViewSubmit={saveViewSubmit}
        advancedFiltersSelections={advancedFiltersSelections}
        selectedColumnFilters={selectedColumnFilters}
        namingView={namingView}
      />
      {/* Name Custom Report Modal */}
      <SaveCustomReportModal
        showNameReportModal={showNameReportModal}
        setShowNameReportModal={setShowNameReportModal}
        setNamingCustomReport={setNamingCustomReport}
        saveCustomReportAs={saveCustomReportAs}
        favoritesData={favoritesData}
      />

      {/* Share Custom Report Modal */}

      <ShareCustomReportModal
        showShareCustomReportModal={showShareCustomReportModal}
        setShowCustomReportModal={setShowCustomReportModal}
        ShareCustomReportWithErrorMessage={ShareCustomReportWithErrorMessage}
        ShareCustomReportWith={ShareCustomReportWith}
        setShareCustomReportWith={setShareCustomReportWith}
        ShareCustomReport={ShareCustomReport}
        selectedCustomReport={selectedCustomReport}
      />

      {/* Bot Suggestion Modal */}

      <BotSuggestionModal
        showModal={showBotSuggestionModal}
        setShowModal={setShowBotSuggestionModal}
        selectedAdAccount={selectedAdAccounts}
        selectedLeadType={selectedLeadType}
        APIEndpointDomain={APIEndpointDomain}
      />

      {/* Advanced Filter Modal */}

      <AdvancedFilterModal
        advancedFilterModalShow={advancedFilterModalShow}
        showAdvancedFiltersModal={showAdvancedFiltersModal}
        consolidationType={consolidationType}
        setStatusFilters={setStatusFilters}
        statusFilters={statusFilters}
        contentTypeFilters={contentTypeFilters}
        setContentTypeFilters={setContentTypeFilters}
        advancedFiltersSelections={advancedFiltersSelections}
        handleAdvanceFilterChange={handleAdvanceFilterChange}
      />

      <div className='w-screen'>
        <Navbar
          fixed='top'
          style={{
            borderWidth: 0
          }}
        >
          {/* Header STARTS HERE  */}

          <div className='w-screen '>
            {/* <div className='py-0 '> */}

            {selectedLevel != 'Summary' ? (
              <div
                className='content-center'
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <>
                  {selectedLevel.toLowerCase() !== 'favorites' &&
                  selectedLevel.toLowerCase() !== 'clusters' ? (
                    <>
                      <Col sm='0' style={{ marginTop: '10px' }}>
                        {/* <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 1500, hide: 400 }}
                        overlay={renderButtonTooltip(
                          "Turn on or off comparison indicators."
                        )}
                      > */}
                        <Button
                          variant={
                            showAvgComparisonIndicators
                              ? 'primary'
                              : 'outline-primary'
                          }
                          onClick={() => {
                            setShowAvgComparisonIndicators(
                              !showAvgComparisonIndicators
                            )
                          }}
                        >
                          <FontAwesomeIcon icon={faUpDown}></FontAwesomeIcon>
                        </Button>
                        {/* </OverlayTrigger> */}
                      </Col>
                      <Col sm='0' style={{ marginTop: '10px' }}>
                        <Button
                          variant={showTrends ? 'primary' : 'outline-primary'}
                          onClick={() => {
                            setShowTrends(!showTrends)
                            setShowSpendTrends(!showTrends)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowTrendUp}
                          ></FontAwesomeIcon>
                        </Button>
                      </Col>
                    </>
                  ) : null}
                  {selectedLevel.toLowerCase() !== 'favorites' &&
                  selectedLevel.toLowerCase() !== 'clusters' &&
                  showTrends ? (
                    <>
                      <Col sm='0' style={{ marginTop: '10px' }}>
                        <Button
                          variant={
                            showSpendTrends ? 'primary' : 'outline-primary'
                          }
                          onClick={() => {
                            setShowSpendTrends(!showSpendTrends)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faIndianRupeeSign}
                          ></FontAwesomeIcon>
                        </Button>
                      </Col>
                    </>
                  ) : null}
                  {/* <Col sm='0' style={{ marginTop: '10px' }}>
                    <Drawer>
                      <DrawerTrigger asChild>
                        <Button
                          variant={
                            AttributedClients > 0 && AttributedRevenue > 0
                              ? 'success'
                              : 'secondary'
                          }

                          // onClick={() => setUtmDrawerOpen(true)}
                        >
                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{' '}
                        </Button>
                      </DrawerTrigger>
                      <DrawerContent className='bg-slate-100'>
                        <div className='mx-auto w-full px-20'>
                          <DrawerHeader>
                            <div
                              className='content-center'
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <h4 className='text-3xl text-slate-800 font-bold mx-auto'>
                                Apply Attributions
                              </h4>
                            </div>
                          </DrawerHeader>

                          <div className=''>
                            <div class='flex items-center justify-center flex-row mt-4'>
                              <Form.Label className='font-semibold mt-1 mr-2'>
                                Clients During:
                              </Form.Label>
                              <Form.Control
                                className='mr-10'
                                style={{ width: '150px' }}
                                size='md'
                                type='text'
                                id='source'
                                placeholder={'Client Attribution'}
                                value={AttributedClients}
                                onChange={handleAttributedClients}
                              />
                              <Form.Label className='font-semibold mt-1  mr-2'>
                                Revenue During:
                              </Form.Label>
                              <Form.Control
                                style={{ width: '150px' }}
                                size='md'
                                type='text'
                                id='medium'
                                placeholder={'Medium Search'}
                                value={AttributedRevenue}
                                onChange={handleAttributedRevenue}
                              />
                            </div>
                          </div>

                          <div className='mx-auto w-full max-w-sm mt-4'>
                            <DrawerFooter>
                              <DrawerClose asChild>
                                <Button
                                  onClick={() => handleGoButtonClick()}
                                  variant='danger'
                                >
                                  Go
                                </Button>
                              </DrawerClose>
                            </DrawerFooter>
                          </div>
                        </div>
                      </DrawerContent>
                    </Drawer>
                  </Col> */}

                  {selectedLevel == 'Ad' ? (
                    <Col sm='0' style={{ marginTop: '10px' }}>
                      <Button
                        variant={aggregateAds ? 'primary' : 'outline-primary'}
                        onClick={() => {
                          setAggregateAds(!aggregateAds)
                        }}
                      >
                        Aggregate
                      </Button>
                    </Col>
                  ) : null}

                  {selectedLevel === 'Favorites' && favoritesData ? (
                    <>
                      <Col sm='0' style={{ marginTop: '10px' }}>
                        {/* <OverlayTrigger
                          placement="left"
                          delay={{ show: 1500, hide: 400 }}
                          overlay={renderButtonTooltip(
                            "Select or share a saved custom report."
                          )}
                        > */}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='secondary'
                            id='dropdown-basic'
                          >
                            {selectedCustomReport == '' ? (
                              <>
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </>
                            ) : (
                              selectedCustomReport
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='custom-report-dropdown-menu'>
                            {customReportNames.length > 0
                              ? customReportNames.map(report => (
                                  <Dropdown.Item>
                                    <Button
                                      onClick={e => {
                                        getCustomReport(report.name)
                                      }}
                                      variant='light'
                                    >
                                      {' '}
                                      {report.name}
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setShowCustomReportModal(true)
                                        setSelectedCustomReport(report.name)
                                      }}
                                      variant='light'
                                    >
                                      <FontAwesomeIcon icon={faShareNodes} />
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        copyCustomReport(report.name)
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faClipboard} />
                                    </Button>
                                  </Dropdown.Item>
                                ))
                              : null}
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* </OverlayTrigger> */}
                      </Col>
                    </>
                  ) : null}

                  {selectedLevel === 'Favorites' ? (
                    <>
                      <Col sm='0' style={{ marginTop: '10px' }}>
                        {/* <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 1500, hide: 400 }}
                          overlay={renderButtonTooltip(
                            "Remove all bookmarks from your custom report."
                          )}
                        > */}
                        <Button
                          onClick={() => deleteFavorites()}
                          variant='light'
                        >
                          Reset
                        </Button>
                        {/* </OverlayTrigger> */}
                      </Col>
                      <Col sm='0' style={{ marginTop: '10px' }}>
                        {/* <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 1500, hide: 400 }}
                          overlay={renderButtonTooltip(
                            "Save changes made to your existing custom report."
                          )}
                        > */}
                        <Button
                          variant='light'
                          onClick={() =>
                            saveCustomReport(
                              favoritesData,
                              selectedCustomReport
                            )
                          }
                        >
                          Save
                        </Button>
                        {/* </OverlayTrigger> */}
                      </Col>
                      <Col sm='0' style={{ marginTop: '10px' }}>
                        {/* <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 1500, hide: 400 }}
                          overlay={renderButtonTooltip(
                            "Save this as a new custom report."
                          )}
                        > */}
                        <Button
                          variant='light'
                          onClick={() => setShowNameReportModal(true)}
                        >
                          Save As
                        </Button>
                        {/* </OverlayTrigger> */}
                      </Col>
                    </>
                  ) : null}
                </>
                {selectedLevel === 'Clusters' ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    <Dropdown autoClose='outside'>
                      <Dropdown.Toggle variant='warning' id='dropdown-basic'>
                        <FontAwesomeIcon icon={faHashtag}></FontAwesomeIcon>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='custom-report-dropdown-menu'>
                        <Form.Control
                          onChange={e => setTagSearch(e.target.value)}
                          type='text'
                          placeholder='Search tags containing...'
                          value={tagSearch}
                        />
                        <button
                          onClick={() => selectAllClusters()}
                          className='w-full rounded-md border border-neutral-300 bg-neutral-100 text-neutral-500 text-sm hover:-translate-y-1 transform transition duration-200 hover:shadow-md my-0'
                        >
                          Select all
                        </button>
                        <button
                          onClick={() => unselectAllClusters()}
                          className='w-full rounded-md border border-neutral-300 bg-neutral-100 text-neutral-500 text-sm hover:-translate-y-1 transform transition duration-200 hover:shadow-md my-0'
                        >
                          Unselect all
                        </button>
                        {clusterNames.map(cluster =>
                          cluster.includes(tagSearch) ? (
                            <>
                              <Dropdown.Item
                                disabled={tableDataAPICalling}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                                className='text-blue-300'
                                onClick={e => {
                                  !selectedClusterNames.includes(cluster)
                                    ? setSelectedClusterNames([
                                        ...selectedClusterNames,
                                        cluster
                                      ])
                                    : setSelectedClusterNames([
                                        ...selectedClusterNames.filter(
                                          name => name !== cluster
                                        )
                                      ])
                                }}
                              >
                                {cluster}{' '}
                                {selectedClusterNames.includes(cluster) ? (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                  ></FontAwesomeIcon>
                                ) : null}
                                {/* <FontAwesomeIcon
                                onClick={() => deleteCluster(cluster)}
                                icon={faTrash}
                              ></FontAwesomeIcon> */}
                              </Dropdown.Item>
                            </>
                          ) : null
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* </OverlayTrigger> */}
                  </Col>
                ) : null}

                {/* {selectedLevel.toLowerCase() !== 'favorites' &&
                selectedLevel.toLowerCase() !== 'clusters' ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                  
                    <Dropdown>
                      <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                        {selectedView == '' ? (
                          <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                        ) : (
                          selectedView
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {allViews.map(view => {
                          return (
                            <>
                              <Dropdown.Item
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                                disabled={tableDataAPICalling}
                                onClick={e => {
                                  selectView(view.name, allViews)
                                }}
                              >
                                {view.name}

                                <FontAwesomeIcon
                                  onClick={() => deleteView(view.name)}
                                  icon={faTrash}
                                />
                              </Dropdown.Item>
                            </>
                          )
                        })}
                        <Dropdown.Item>
                          <Button
                            onClick={() => setShowNameYourViewModal(true)}
                            variant='success'
                          >
                            Save Current View
                          </Button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                ) : null} */}
                {/* <Col sm="0" style={{ marginTop: "10px" }}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip(
                      "Exclude campaigns, adsets, ads and dates from your data!"
                    )}
                  >
                    <Button
                      variant="secondary"
                      onClick={() => setShowExclusionModal(true)}
                      disabled={tableDataAPICalling}
                    >
                      <FontAwesomeIcon icon={faBan} />
                      {"  "}
                      <Badge bg="danger">
                        {campaignExclusions.length +
                          adsetExclusions.length +
                          adExclusions.length +
                          dateExclusions.length}
                      </Badge>
                    </Button>
                  </OverlayTrigger>
                </Col> */}
                {selectedLevel.toLowerCase() !== 'favorites' ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    <Button variant='light' size='md' onClick={showColumnModal}>
                      <FontAwesomeIcon icon={faTableColumns}></FontAwesomeIcon>
                    </Button>
                  </Col>
                ) : null}
                {selectedLevel.toLowerCase() !== 'favorites' &&
                selectedLevel.toLowerCase() !== 'clusters' ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    {/* Advanced Filter Button */}
                    {/* <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 1500, hide: 400 }}
                      overlay={renderButtonTooltip(
                        "Apply filters to narrow down your data."
                      )}
                    > */}
                    <Button
                      variant='light'
                      size='md'
                      onClick={showAdvancedFiltersModal}
                      disabled={compareRangeRequirement}
                    >
                      <FontAwesomeIcon
                        className='fa-light'
                        icon={faFilter}
                      ></FontAwesomeIcon>{' '}
                      {/* <Badge bg="danger">
                        {countAppliedFilters(
                          defaultAdvancedFilters,
                          advancedFiltersSelections
                        )}
                      </Badge> */}
                    </Button>
                    {/* </OverlayTrigger> */}
                  </Col>
                ) : null}

                {Data &&
                Data.length > 0 &&
                selectedLevel.toLowerCase() !== 'favorites' &&
                selectedLevel.toLowerCase() !== 'clusters' &&
                !selectedAdAccounts.includes('MiM-Essay_GA') &&
                !selectedAdAccounts.includes('MentR-Me_GA') ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    {/* Advanced Filter Button */}
                    {/* <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 1500, hide: 400 }}
                      overlay={renderButtonTooltip(
                        "Apply filterss to narrow down your data."
                      )}
                    > */}
                    <Button variant='light' size='md'>
                      <CSVLink
                        filename='performance-analytics.csv'
                        data={[...Data, ...totalsData]}
                      >
                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                      </CSVLink>
                    </Button>
                    {/* </OverlayTrigger> */}
                  </Col>
                ) : null}
                {/* 
              {selectedLevel.toLowerCase() !== 'favorites' &&
              selectedLevel.toLowerCase() !== 'clusters' &&
              !selectedAdAccount.toLowerCase().includes('_ga') ? (
                <Col sm='0' style={{ marginTop: '10px' }}>
              
                  <Button
                    variant='light'
                    size='md'
                    onClick={() => setShowBotSuggestionModal(true)}
                  >
                    <FontAwesomeIcon
                      className='fa-light'
                      icon={faRobot}
                    ></FontAwesomeIcon>{' '}
          
                  </Button>
                </Col>
              ) : null} */}
                {/* <Col sm="0" style={{ marginTop: "10px" }}>
                  Reset FILTERS Button

                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip("Reset all applied filters.")}
                  >
                    <Button variant="dark" size="md" onClick={resetFilters}>
                      Reset Filters
                    </Button>
                  </OverlayTrigger>
                </Col> */}

                {!['favorites', 'clusters'].includes(
                  selectedLevel.toLowerCase()
                ) ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    <Form.Control
                      size='md'
                      type='text'
                      id='campaign'
                      placeholder={'Campaign Search'}
                      onChange={handleCampaignSearch}
                      value={campaignSearch}
                    />
                  </Col>
                ) : null}
                {!['favorites', 'clusters'].includes(
                  selectedLevel.toLowerCase()
                ) ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    <Form.Control
                      size='md'
                      type='text'
                      id='adset'
                      placeholder='Adset Search'
                      onChange={handleAdsetSearch}
                      value={adsetSearch}
                    />
                  </Col>
                ) : null}
                {!['favorites', 'clusters'].includes(
                  selectedLevel.toLowerCase()
                ) ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    <Form.Control
                      size='md'
                      type='text'
                      id='ad'
                      p
                      placeholder='Ad Search'
                      onChange={handleAdSearch}
                      value={adSearch}
                    />
                  </Col>
                ) : null}

                {!['clusters'].includes(selectedLevel.toLowerCase()) &&
                selectedLeadType != 'Cold Call' ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    {/* <OverlayTrigger
                    placement="left"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip("Select required ad account.")}
                  > */}
                    <Dropdown autoClose='outside'>
                      <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                        {selectedAdAccounts.length == 0
                          ? 'Select Account'
                          : 'Selected ' +
                            selectedAdAccounts.length +
                            ' Accounts'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {adAccounts.map(account_name => {
                          return (
                            <Dropdown.Item
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              className='text-blue-300'
                              onClick={e =>
                                selectedAdAccounts.includes(account_name)
                                  ? setSelectedAdAccounts(
                                      selectedAdAccounts.filter(
                                        account => account != account_name
                                      )
                                    )
                                  : setSelectedAdAccounts([
                                      ...selectedAdAccounts,
                                      account_name
                                    ])
                              }
                              disabled={tableDataAPICalling}
                              value={account_name}
                            >
                              {account_name}{' '}
                              {selectedAdAccounts.includes(account_name) && (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                ></FontAwesomeIcon>
                              )}
                            </Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                ) : null}

                {['Campaign', 'Adset', 'Ad', 'Clusters'].includes(
                  selectedLevel
                ) ? (
                  <>
                    <Col sm='0' style={{ marginTop: '10px' }}>
                      {/* <OverlayTrigger
                    placement="left"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip("Select required lead type.")}
                  > */}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='secondary'
                          id='dropdown-basic'
                        >
                          {selectedLeadType}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={e => setSelectedLeadType(e.target.text)}
                            disabled={tableDataAPICalling}
                          >
                            CPA
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={e => setSelectedLeadType(e.target.text)}
                            disabled={tableDataAPICalling}
                          >
                            Premium
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={e => setSelectedLeadType(e.target.text)}
                            disabled={tableDataAPICalling}
                          >
                            Premium + CPA
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={e => setSelectedLeadType(e.target.text)}
                            disabled={tableDataAPICalling}
                          >
                            Jobs
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={e => setSelectedLeadType(e.target.text)}
                            disabled={tableDataAPICalling}
                          >
                            GMAT
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            onClick={e => setSelectedLeadType(e.target.text)}
                            disabled={tableDataAPICalling}
                          >
                            Cold Call
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* </OverlayTrigger> */}
                    </Col>
                  </>
                ) : null}
                {selectedLevel == 'Clusters' ? (
                  <Col sm='0' style={{ marginTop: '10px' }}>
                    <Button variant='light'>
                      <CSVLink
                        filename='performance-crm-export.csv'
                        data={exportCSVData}
                      >
                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                      </CSVLink>
                    </Button>
                  </Col>
                ) : null}
                <Col sm='0' style={{ marginTop: '10px' }}>
                  <Form.Control
                    disabled={tableDataAPICalling}
                    defaultValue={defaultStartDate}
                    onChange={handleStartDate}
                    type='date'
                    size='md'
                  />
                </Col>
                {/* </Form.Group> */}
                <Col sm='0' style={{ marginTop: '10px' }}>
                  <Form.Control
                    disabled={tableDataAPICalling}
                    defaultValue={defaultEndDate}
                    onChange={handleEndDate}
                    type='date'
                    size='md'
                  />
                </Col>
                <Col sm='0' style={{ marginTop: '10px' }}>
                  {/* <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip(
                      "Click to fetch the latest data! (Only required if you've changed the date, ad account or lead type)"
                    )}
                  > */}
                  <Button
                    className='mb-2'
                    size='sm'
                    id='toggle-check'
                    variant='danger'
                    onClick={() => handleGoButtonClick()}
                    disabled={
                      advancedFilterModalShow ||
                      columnModalShow ||
                      tableDataAPICalling ||
                      selectedLevel === 'Favorites' ||
                      (selectedLevel === 'Clusters' &&
                        selectedClusterNames.length === 0)
                    }
                  >
                    Go
                  </Button>
                  {/* </OverlayTrigger> */}
                </Col>
              </div>
            ) : null}
            {/* </div> */}
            <div className='pt-1'>
              <TabsATUI
                tabs={
                  tableData && tableData.length > 0
                    ? [
                        {
                          title: 'Campaign ' + tabMessages.campaign,
                          value: 'Campaign'
                        },
                        {
                          title: 'Adset ' + tabMessages.adset,
                          value: 'Adset'
                        },
                        {
                          title: 'Ad ' + tabMessages.ad,
                          value: 'Ad'
                        },
                        {
                          title: 'Favorites',
                          value: 'Favorites'
                        }
                        // {
                        //   title: 'Clusters',
                        //   value: 'Clusters'
                        // }
                      ]
                    : [
                        {
                          title: 'Campaign ' + tabMessages.campaign,
                          value: 'Campaign'
                        },
                        {
                          title: 'Adset ' + tabMessages.adset,
                          value: 'Adset'
                        },
                        {
                          title: 'Ad ' + tabMessages.ad,
                          value: 'Ad'
                        },
                        {
                          title: 'Favorites',
                          value: 'Favorites'
                        }
                        // {
                        //   title: 'Clusters',
                        //   value: 'Clusters'
                        // }
                      ]
                }
                levelSelector={setSelectedLevel}
                selectedLevel={selectedLevel}
                disableButtons={tableDataAPICalling}
              />
            </div>
          </div>
        </Navbar>
      </div>
    </>
  )
}

export default PerformanceHeader
